import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const About = () => {
    useEffect(()=>{
document.title = "Our Services | Bloomleaf Organic";

},[]);
  return (
    <section className="section-cover section-cover-b section-cover-c our-services-cover">
         <section className='home-service-cover home-service-cover-a'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
                           {/* <img src='./images/11.png' className='logo-image'></img> */}
     <h3 className="category-h3">Services We Offer</h3> 
                                    {/* <p className="category-p category-p-a category-p-b">Indulge in the rich and luscious world of Nshozi Cocoa, offering the finest cocoa products in both beans and powder forms.</p>    */}
    <div className='home-services-container home-services-container-a'>
      
       <div className='home-services-info '>

        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <img src='./images/14.jpg' alt='image' className='service-image'></img>
          <h3 className='home-service-title home-service-title-a'>Hire of Agricultural Equipment</h3>
          <p>Access to modern and efficient agricultural equipment is crucial for enhancing productivity and reducing labor-intensive tasks. At Bloomleaf Organic, we offer a diverse range of agricultural equipment for hire, including tractors, plows, harvesters, and more. Our well-maintained machinery is designed to maximize yields and optimize farming operations.</p>

        </div>
       
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
           {/* <img src='./images/13.jpg' alt='image' className='service-image'></img> */}
          <img src='./images/20.jpg' alt='image' className='service-image'></img>
          <h3 className='home-service-title home-service-title-a'>Agricultural Consultancy</h3>
          <p>Our team of experienced agricultural consultants is here to provide personalized guidance and expertise. Whether you are starting a new venture, facing challenges in your current practices, or seeking ways to improve efficiency, our consultants will work closely with you to develop tailored solutions that align with your goals.</p>

        </div>
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          {/* <img src='./images/15.jpg' alt='image' className='service-image'></img> */}
          <img src='./images/19.jpg' alt='image' className='service-image'></img>
          <h3 className='home-service-title home-service-title-a'>Training and Workshops</h3>
          <p>Knowledge is power, and we believe in empowering farmers with the latest agricultural practices and techniques. We organize training programs and workshops to equip farmers with the skills and knowledge necessary to adapt to modern agricultural practices, sustainable farming methods, and innovative technologies.</p>

        </div>
    </div>
    
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}
    </div>
    {/* <p className="category-p category-p-a category-p-c">Experience the finest cocoa offerings from Nshozi, whether you prefer the essence of unprocessed cocoa beans or the convenience of luxuriously smooth cocoa powder. Unleash your culinary creativity with Nshozi Cocoa and revel in the unparalleled richness of cocoa in every delightful bite and sip.</p> */}
  </section>              
    </section>
  )
}
export default About