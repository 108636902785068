import React, {useEffect} from 'react';

 
 
const About = () => {
    useEffect(()=>{
document.title = "About Us | Bloomleaf Organic";

},[]);
   
  return (
    <section className="section-cover">
        
        <div className="content-container-b">
            <article className="about-row">
                <div className="about-image">
                    <div className="image-circle-one">
                    </div>
                    
                    {/* <img src='./images/17b.jpg' className="about-photo"></img> */}
<img src='./images/23.jpg' className="about-photo"></img>
                </div>
                <div className="about-text">
                    <div className="text-circle-one">
                    </div>
                    <div className="text-circle-two">
                    </div>
                    <h2 className="heading-h2">Our Purpose</h2>
                    <p className="paragraph" >
<strong>Bloomleaf Organic</strong> was founded with a vision to create a more inclusive and sustainable agricultural ecosystem. We firmly believe that empowering smallholder farmers is key to achieving this goal. By partnering with local communities, we source organic products directly from the farmers, cutting out unnecessary middlemen and ensuring that they receive fair prices for their hard work.
                    </p>
                    
                </div>

            </article>
            <article className="about-row about-row-a">
                <div className="about-image">
                    <div className="image-circle-one">
                    </div>
                    
                    <img src='./images/16.jpg' className="about-photo"></img>

                </div>
                <div className="about-text">
                    <div className="text-circle-one">
                    </div>
                    <div className="text-circle-two">
                    </div>
                    <h2 className="heading-h2">Supporting Smallholder Farmers</h2>
                    <p className="paragraph" >
Smallholder farmers play a crucial role in global agriculture, yet they often face challenges in accessing markets and getting fair prices for their produce. At <strong>Bloomleaf Organic</strong>, we bridge this gap by providing them with a platform to showcase their organic harvest and connect with markets that appreciate the value of their efforts.
                    </p>
                    
                </div>

            </article>
            <article className="about-row about-row-b">
                <div className="about-image">
                    <div className="image-circle-one">
                    </div>
                    
                    <img src='./images/18a.jpg' className="about-photo"></img>

                </div>
                <div className="about-text">
                    <div className="text-circle-one">
                    </div>
                    <div className="text-circle-two">
                    </div>
                    <h2 className="heading-h2">Reducing Post-Harvest Losses</h2>
                    <p className="paragraph" >
We understand that post-harvest losses can be detrimental to farmers' livelihoods and the environment. Through proper training and support, we help farmers implement effective post-harvest practices, minimizing waste and optimizing the quality of their yield.
                    </p>
                    
                </div>

            </article>

        </div>


    </section>
  )
}
export default About