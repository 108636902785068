import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const About = () => {
    useEffect(()=>{
document.title = "Organic Cocoa | Bloomleaf Organic";

},[]);
  return (
    <section className="section-cover section-cover-b section-cover-c">
         <section className='home-service-cover home-service-cover-a'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
                           <img src='./images/11.png' className='logo-image'></img>
     {/* <h3 className="category-h3 category-h3-a">Arabica and Robusta Collection</h3>  */}
                                    <p className="category-p category-p-a category-p-b">Indulge in the rich and luscious world of Nshozi Cocoa, offering the finest cocoa products in both beans and powder forms.</p>   
    <div className='home-services-container home-services-container-a'>
      
       <div className='home-services-info '>

        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <h3 className='home-service-title'>Cocoa Beans</h3>
          <p>Discover the pure essence of cocoa in its natural state with Nshozi Cocoa Beans. Sourced from select cocoa plantations, these beans are carefully harvested and fermented to unlock their deep and complex flavors. Perfect for chocolate connoisseurs and artisanal chocolatiers, these premium cocoa beans allow you to craft your own delectable creations and experience the authentic taste of cocoa in its raw form.</p>

        </div>
       
        <div className='home-service-box home-service-box-a'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><CiCoffeeBean></CiCoffeeBean></span>
          </div> */}
          <h3 className='home-service-title'>Cocoa Powder</h3>
          <p>Elevate your baking and hot chocolate experience with Nshozi Cocoa Powder. Made from finely ground cocoa beans, this rich and velvety powder adds a burst of indulgence to your favorite recipes. Whether it's baking decadent desserts, preparing sumptuous hot cocoa, or incorporating cocoa goodness into smoothies, Nshozi Cocoa Powder is a versatile and delightful addition to your kitchen.</p>

        </div>
    </div>
    
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}
    </div>
    <p className="category-p category-p-a category-p-c">Experience the finest cocoa offerings from Nshozi, whether you prefer the essence of unprocessed cocoa beans or the convenience of luxuriously smooth cocoa powder. Unleash your culinary creativity with Nshozi Cocoa and revel in the unparalleled richness of cocoa in every delightful bite and sip.</p>
  </section>              
    </section>
  )
}
export default About