import React, {useEffect} from 'react';
import {FaLeaf} from 'react-icons/fa';

const About = () => {
    useEffect(()=>{
document.title = "Impact | Bloomleaf Organic";
},[]);
  return (
    <section className="section-cover">  
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='heading-h2 heading-h2-a'>Our Positive Footprint</h2>
              {/* <div className='line1'></div> */}
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaLeaf></FaLeaf>
                          </span> */}
                          <p className='goal-description goal-description1 goal-description2'>
                            We measure our success not just in terms of profits but also in the positive impact we create. Through our efforts, we have:
</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaLeaf></FaLeaf>
                          </span>
                          <p className='goal-description goal-description1'>Empowered hundreds of smallholder farmers, providing them with fair incomes and improved livelihoods.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaLeaf></FaLeaf>
                          </span>
                          <p className='goal-description goal-description1'>Reduced post-harvest losses, minimizing food waste and promoting sustainable agricultural practices.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaLeaf></FaLeaf>
                          </span>
                          <p className='goal-description goal-description1'>Contributed to the conservation of biodiversity and the preservation of traditional farming methods.</p>
                     </div>
                </div>
           </article>
           <article className='goals-slides'>
               {/* <GoalsCarousel></GoalsCarousel> */}
               <img src='images/17a.jpg' className='scope-image'></img>
                {/* <img src='images/1.jpg' className='scope-image'></img> */}
           </article>
       </div>
    </section>
  )
}
export default About