import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const About = () => {
    useEffect(()=>{
document.title = "Our Products | Bloomleaf Organic";

},[]);
  return (
    <section className="section-cover">
             <h3 className="category-h3 category-h3-b">Our Products</h3> 
        <div className="catergories-container">
                           <article className="category">
                             <div className="category-image">
                                    {/* <img src="./images/2.jpg" alt="Image" className="category-photo"/> */}
                              <img src="./images/13.jpg" alt="Image" className="category-photo"/>
                             </div>

                             <div className="category-content">
                                    <h3 className="category-h3">Organic Coffee</h3> 
                                    <p className="category-p">Savor the rich flavors of our premium organic coffee beans, grown with care by dedicated smallholder farmers.</p>   
                                    
                                    {/* <a href="store.html" className="category-link">See More</a> */}
                                    <Link to='/organic-coffee' className="category-link" onClick={scrollFunc}>Read More</Link>

                            </div>


                           </article>

                           <article className="category">
                            <div className="category-image">
                                   <img src="./images/18.jpg" alt="Image" className="category-photo"/>
                            </div>

                            <div className="category-content">
                                   <h3 className="category-h3">Organic Cocoa</h3> 
                                   <p className="category-p">Experience the velvety smoothness of our organic cocoa, knowing that each bean represents the hard work of passionate farmers.</p>   
                                   
                                   {/* <a href="store.html" className="category-link">See More</a> */}
 <Link to='/organic-cocoa' className="category-link" onClick={scrollFunc}>Read More</Link>
                           </div>


                          </article>

                          <article className="category">
                            <div className="category-image">
                                   <img src="./images/16.jpg" alt="Image" className="category-photo"/>
                            </div>

                            <div className="category-content">
                                   <h3 className="category-h3">Organic Cashew Nuts</h3> 
                                   {/* <p className="category-p">Our sustainable rubber production not only offers top-quality products but also empowers rubber-tapping communities.</p>    */}
                                   
                                   {/* <a href="store.html" className="category-link">See More</a> */}

                           </div>


                          </article>

                          <article className="category">
                           <div className="category-image">
                                  {/* <img src="./images/17a.jpg" alt="Image" className="category-photo"/> */}
                                  <img src="./images/22.jpg" alt="Image" className="category-photo"/>
                           </div>

                           <div className="category-content">
                                  <h3 className="category-h3">
                                   {/* Organic Cereals */}
                                   Nshozi Mixed Grains (Maize, Soya Beans, and Sim Sim)
                                   </h3> 
                                  <p className="category-p">
                                   {/* Start your day with wholesome and nourishing organic cereals, knowing they are sourced ethically and sustainably. */}
                                   Discover the perfect balance of taste, nutrition, and versatility with Bloomleaf Mixed Grains. Our premium blend combines three wholesome grains - maize, soya beans, and sim sim (sesame seeds) - for a nourishing and delightful culinary experience.
                                   </p>   
                                  
                                  {/* <a href="store.html" className="category-link">See More</a> */}

                          </div>


                         </article>


                 </div>


    </section>
  )
}
export default About