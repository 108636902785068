import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-slides">
      <Carousel.Item>
        <img
          className="d-block w-100 first-home-slide"
          // src="./images/4.jpeg"
          // src="./images/1.jpg"
          src="./images/13.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">BLOOMLEAF ORGANIC</h3>
          {/* <p className="slide-p slide-p1">"My Customer, My Responsibility"</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          //  src="./images/1.jpg"
          // src="./images/3.jpg"
          src="./images/18.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p slide-p2">Promoting sustainable agriculture </p>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/17.jpg"
          src="./images/21a.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p3">
Empowering smallholder farmers
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/7.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
     Bringing you the finest selection of organic agricultural products
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      
      <Carousel.Item>
        <img
          className="d-block w-100 fifth-home-slide"
          src="./images/5.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p5">
          Reducing post-harvest losses
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      
    </Carousel>
  );
}

export default CarouselFade;