import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
 
const About = () => {
    useEffect(()=>{
document.title = "Join Us | Bloomleaf Organic";

},[]);
   
  return (
    <section className="section-cover">
        
        <div className="content-container-b">
            <article className="about-row">
                <div className="about-image">
                    <div className="image-circle-one">
                    </div>
                    
                    <img src='./images/16.jpg' className="about-photo" alt="image"></img>

                </div>
                <div className="about-text about-text-a">
                    <div className="text-circle-one">
                    </div>
                    <div className="text-circle-two">
                    </div>
                    <h2 className="heading-h2">Together, We Grow Stronger</h2>
                    <p className="paragraph" >
Whether you are a conscious consumer, a retailer, or a distributor, join us in our journey towards a more sustainable future. By choosing <strong></strong>Bloomleaf Organic products, you support smallholder farmers, encourage sustainable agriculture, and make a positive difference in the world.
                    </p>
                    <Link to='/contact' className='link-button'>Join Us</Link>
                </div>

            </article>
        </div>
    </section>
  )
}
export default About