import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaYoutube,FaInstagram} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (

       <section className='footer-bottom' >
      {/* <div className='footer-content'>





             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                       

                         <div className='footer-link'>
                              <Link to='/' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>
                        <div className='footer-link'>
                              <Link to='#about' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='#' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Product Range</p></Link> 
                        </div>
                         <div className='footer-link'>
                                <Link to='#' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Impact</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='#' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Join Us</p></Link> 
                        </div>

                        
                    </div>

             </div>

            

             <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div>

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Location</h3>
                      <p>
                      Buganda Road Flats, Block 663 Flat H, Kampala - Uganda
                        </p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                      +256772 401824 <br></br> +256700 570 066
                      </p>

                    </div>

                   
                      

             </div>

          <div className='footer-inner-content'>


                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='#contact' onClick={scrollFunc} className='email-link'>  <p>info@bloomleaforganic.com</p></Link> 
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaFacebookSquare></FaFacebookSquare></a>
                                 </span>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaTwitterSquare></FaTwitterSquare></a>
                                 </span>

                                 <span>
<a href="#" target="_blank" className='social-link'><FaLinkedin></FaLinkedin></a>
                                 </span>  
                         </div>
                        
                        
                      </div>

             </div>
        
        
        </div> */}
        <div className='footer-icons'>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaFacebookSquare></FaFacebookSquare></a>
                                 </span>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaTwitterSquare></FaTwitterSquare></a>
                                 </span>

                                 <span>
<a href="#" target="_blank" className='social-link'><FaLinkedin></FaLinkedin></a>
                                 </span>  
                                 <span>
<a href="#" target="_blank" className='social-link'><FaInstagram></FaInstagram></a>
                                 </span>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaYoutube></FaYoutube></a>
                                 </span>
                         </div>
       
            <span>
                      &copy; {new Date().getFullYear()} <span className="date"></span> Bloomleaf Organic
                      </span>
        
    </section>
  )
}

export default Footer